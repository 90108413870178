// User Access Permissions
export const USER_ACCESS = {
    ADMIN: 'ADMIN',
    MULTI_FRANCHISE_MANAGER: 'MULTI_FRANCHISE_MANAGER',
    MANAGER: 'MANAGER',
    MEMBER: 'MEMBER',
    NO_ACCESS: 'NO_ACCESS'
}

export const getUserAccessLevel = ({ adminPermissions, managedFranchises }) => {
    if (adminPermissions?.indexOf('franchise') > -1) return USER_ACCESS.ADMIN
    if (managedFranchises?.length > 1) return USER_ACCESS.MULTI_FRANCHISE_MANAGER
    if (managedFranchises?.length === 1) return USER_ACCESS.MANAGER
    return USER_ACCESS.MEMBER
}