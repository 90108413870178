import {
    SET_LOADING,
    LOAD_FRANCHISE, UPDATE_MEMBERS, LOAD_MEMBER, LOAD_LOCATIONS, CLEAR_FRANCHISE,
    LOAD_TOP_TEXT_SUGGESTIONS, LOAD_FRANCHISE_LABELS,
    CREATE_MEMBER,
    SELECT_LOCATION, UPDATE_LOCATION, DELETE_LOCATION,
    GET_FRANCHISE_IMAGES, GET_FRANCHISE_LOGOS, ADD_FRANCHISE_IMAGE, ADD_FRANCHISE_LOGO, LOAD_FRANCHISE_COLORS, LOAD_ALL_FONTS, CHANGE_LABEL, DELETE_LABEL, UPDATE_GTM_CONTAINER_ID,
    REFRESH_API_TOKENS,
    TOKEN_APIKEY,
    LOAD_FRANCHISE_APPROVALS,
    UPDATE_FRANCHISE,
    ADD_DEFAULT_LOGO,
    UPDATE_LOCATION_FIELD,
    UPDATE_MULTIPLE_LOCATIONS_KEYWORDS,
    CHAT_GPT_SUGGESTIONS,
    LOAD_MEMBERS,
    UPDATE_FRANCHISE_IMAGE,
    UPDATE_FRANCHISE_LOGO,
    DELETE_FRANCHISE_IMAGE,
    DELETE_FRANCHISE_LOGO,
    LOAD_FONT,
    DELETE_FONT,
    YOUTUBE_AI_SUGGESTIONS,
} from './types';

import { staggerApiCalls } from '../helpers'
import { toast } from 'react-toastify';
import { API } from '../constants/api';
import { loadBrandTheme } from './UIActions';
import { batchifyRequest, makeRequest } from '../helpers/make-request';
import { loadFontsToHtml } from '../helpers/font.utils';
import { FEATURE_QUERY_PARAM } from '../helpers/franchise-payments.utils';
import { hideOverlay } from './overlayActions';

export const KEYWORD_UPDATE_TYPES = {
    ADD: 'add',
    REPLACE: 'replace',
    REMOVE: 'remove'
}

export const setLoading = bool => dispatch => dispatch({ type: SET_LOADING, payload: bool })

export const clearFranchise = () => dispatch => dispatch({ type: CLEAR_FRANCHISE })

export const updateFranchise = (updatedProperties) => dispatch =>  {
    dispatch({ type: UPDATE_FRANCHISE, payload:  updatedProperties})
}

export const loadFranchise = (franchiseName) => dispatch => {
    try {
        if (franchiseName) {
            window.eulerity.makeApiCall(`${API.FRANCHISE.GET_FRANCHISE}?franchise=` + encodeURIComponent(franchiseName), 'GET', null, response => {
                console.log('Loaded Franchise', response)
                dispatch({ type: LOAD_FRANCHISE, payload: response })
                if(response?.theme) dispatch(loadBrandTheme(response.theme))
            })
        } else {
            dispatch({ type: CLEAR_FRANCHISE })
        }
    } catch (err) {
        console.error('Unable to load franchise list');
    }
}

export const updateMembers = (user) => dispatch => {
    console.log('Loading franchisee data for ' + user.name)
    dispatch({
        type: UPDATE_MEMBERS,
        payload: user
    })
}

export const loadMemberList = (userIds, onDoneLoading) => dispatch => {
    try {
        let s = Date.now();
        if (userIds.length) {
            let apiCalls = [];
            let numLocations = 0;
            let loadedMemberDataCount = 0;
			dispatch(setLoading(true))
            userIds.forEach(uid => {
                apiCalls.push(() => {
                    window.eulerity.makeApiCall({
                        url: `${API.USER.GET_FULL}?exclude&uid=${uid}`,
                        method: 'GET',
                        callback: response => {
                            const { id, name, email, lastLoginDays } = response;
                            dispatch({
                                type: LOAD_MEMBER,
                                payload: { id, name, email, lastLoginDays }
                            })
                            //Process the User's Locations
                            dispatch({
                                type: LOAD_LOCATIONS,
                                payload: response.locations
                            })

                            numLocations += response.locations.length;

                            // Refresh data that should be updated when more locations are loaded in.
                            dispatch({ type: LOAD_FRANCHISE_LABELS })

                            // Update loadedMemberDataCount and check if done loading all user location and creative data
                            loadedMemberDataCount++;
                            if (loadedMemberDataCount === userIds.length) {
                                let e = Date.now();
                                console.log(`Received ${userIds.length} users with ${numLocations} locations in ${(e - s)/1000}s`);

                                onDoneLoading?.();
								dispatch(setLoading(false))
                            }
                        },
                        retry: 3
                    })
                });
            });

            staggerApiCalls(apiCalls, 'read')

        }
    } catch (err) {
        console.error('Unable to load franchise members');
    }
}

export const loadUsersAndLocations = (cb = (() => {})) => (dispatch, getState) => {
    const s = Date.now();
    const franchiseName = getState().franchise.name;

    const baseFranchiseListRequest = makeRequest().retry(3).param("franchise", franchiseName);

    const usersRequest = batchifyRequest(baseFranchiseListRequest.url(API.FRANCHISE.LIST_USERS));

    const locationsRequest = batchifyRequest(baseFranchiseListRequest.url(API.FRANCHISE.LIST_LOCATIONS));

    locationsRequest.setDataHandler((data, next) => {
        dispatch({ type: LOAD_LOCATIONS, payload: data })

        next();
    })

    Promise.all([usersRequest.run(), locationsRequest.run()]).then(([users, locations]) => {
        const e = Date.now();
        console.log(`Received ${users.length} users with ${locations.length} locations in ${(e - s)/1000}s`);

        dispatch({type: LOAD_MEMBERS, payload: users});
        // locations are added to redux in the locationsRequest data handler

        cb();
    }).catch(() => {
        toast.error("Something went wrong loading campaigns!");
    })
}

// USER ACTIONS
export const createFranchisee = ({ franchiseName, userObj, callback, errorCallback }) => dispatch => {
    window.eulerity.makeApiCall({
        url: `${API.FRANCHISE.CREATE_FRANCHISEE}?franchise=` + encodeURIComponent(franchiseName),
        method: 'POST',
        obj: userObj,
        callback: response => {
            dispatch({
                type: CREATE_MEMBER,
                payload: response
            })
            callback?.(response);
        },
        errorCallbacks: {
            //Error handling
            1000: (error) => {
                let messageObj = JSON.parse(error.response);
                errorCallback?.(messageObj.message);
            },
        },
        retry: 3
    })
}

// LOCATION ACTIONS
export const selectLocation = (location) => dispatch => {
    dispatch({
        type: SELECT_LOCATION,
        payload: location
    })
}

export const updateLocation = ({uid, lid, data, invalidLandingPage, callback, errorCallback, isDefaultLoc=false}) => dispatch => {
    if (!uid || !lid) return;
    let url = `${API.LOCATION.UPDATE}?uid=` + uid + '&lid=' + lid

    // We are updating the landing page, so we should also set skipWebsiteValidation accordingly
    if (data.hasOwnProperty('website')) {
        data.skipWebsiteValidation = invalidLandingPage
    }

    window.eulerity.makeApiCall({
        url,
        method: 'POST',
        obj: data,
        retry: 3,
        callback: (response) => {
            dispatch({
                type: UPDATE_LOCATION,
                payload: response,
                isDefaultLoc,
            })
            //Refresh Franchise Wide Data if Needed
            if (!isDefaultLoc) {
                const doUpdateLabels = data.hasOwnProperty('labels')
                if (doUpdateLabels) dispatch({ type: LOAD_FRANCHISE_LABELS })
            }
            callback?.(response);
        },
        errorCallbacks: {
            1000: (err) => {
                console.error(err)
                errorCallback?.()
            }
        }
    });
}

export const updateLocationField = ({ location, field, data }) => dispatch => {
    dispatch({
        type: UPDATE_LOCATION_FIELD,
        payload: {
            location,
            field,
            data
        }
    })
}



// ASSETS
export const addImage = ({ franchiseName, blobKey, labels = [], cb }) => dispatch => {
    const request = makeRequest()
        .url(API.FRANCHISE.ADD_IMAGE)
        .param('franchise', franchiseName)
        .param('blobKey', blobKey)
        .body({labels})

    return new Promise((resolve, reject) => {
        request
            .post()
            .then((response) => {
                dispatch({
                    type: ADD_FRANCHISE_IMAGE,
                    payload: response
                })
                cb?.(response);
                resolve()
            })
            .catch((error) => {
                console.error(error)
                reject()
            })
    })
}

export const addLogo = ({ franchiseName, blobKey, labels=[], cb }) => dispatch => {
    const request = makeRequest()
        .url(API.FRANCHISE.ADD_LOGO)
        .param('franchise', franchiseName)
        .param('blobKey', blobKey)
        .body({ labels })

        return new Promise((resolve, reject) => {
            request
                .post()
                .then((response) => {
                    dispatch({
                        type: ADD_FRANCHISE_LOGO,
                        payload: response
                    })
                    cb?.(response);
                    resolve()
                })
                .catch((error) => {
                    console.error(error)
                    reject()
                })
        })
}


export const getFranchiseImages = (franchiseName) => dispatch => {
    const request = makeRequest()
        .url(API.FRANCHISE.GET_IMAGES)
        .param('franchise', franchiseName)

    return new Promise((resolve, reject) => {
        request
            .get()
            .then(response => {
                response.images.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                dispatch({
                    type: GET_FRANCHISE_IMAGES,
                    payload: response
                })
                resolve()
            })
            .catch(err => {
                console.err(err)
                reject()
            })
    })
}

export const getFranchiseLogos = (franchiseName) => dispatch => {
    const request = makeRequest()
        .url(API.FRANCHISE.GET_LOGOS)
        .param('franchise', franchiseName)

    return new Promise((resolve, reject) => {
        request
            .get()
            .then(response => {
                response.images.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                dispatch({
                    type: GET_FRANCHISE_LOGOS,
                    payload: response
                })
                resolve()
            })
            .catch(err => {
                console.error(err)
                reject()
            })
    })
}

export const updateImageLabels = (franchiseName, blobKey, labels) => dispatch => {
    const request = makeRequest()
        .url(API.FRANCHISE.UPDATE_IMAGE)
        .param('franchise', franchiseName)
        .param('blobKey', blobKey)
        .body({ labels })

    return new Promise((resolve, reject) => {
        request
            .post()
            .then(response => {
                dispatch({
                    type: UPDATE_FRANCHISE_IMAGE,
                    payload: response
                })
                resolve()
            })
            .catch(err => {
                console.err(err)
                reject()
            })
    })
}

export const updateLogoLabels = (franchiseName, blobKey, labels) => dispatch => {
    const request = makeRequest()
        .url(API.FRANCHISE.UPDATE_LOGO)
        .param('franchise', franchiseName)
        .param('blobKey', blobKey)
        .body({ labels })

    return new Promise((resolve, reject) => {
        request
            .post()
            .then(response => {
                dispatch({
                    type: UPDATE_FRANCHISE_LOGO,
                    payload: response
                })
                resolve()
            })
            .catch(err => {
                console.err(err)
                reject()
            })
    })
}

export const deleteSelectedImage = (franchiseName, blobKey) => dispatch => {
    const request = makeRequest()
        .url(API.FRANCHISE.DELETE_IMAGE)
        .param('franchise', franchiseName)
        .param('blobKey', blobKey)

    return new Promise((resolve, reject) => {
        request
            .post()
            .then(_response => {
                dispatch({
                    type: DELETE_FRANCHISE_IMAGE,
                    payload: { blobKey }
                })
                resolve()
            })
            .catch(err =>{
                console.error(err)
                reject()
            })
    })
}

export const deleteSelectedLogo = (franchiseName, blobKey) => dispatch => {
    const request = makeRequest()
    .url(API.FRANCHISE.DELETE_LOGO)
    .param('franchise', franchiseName)
    .param('blobKey', blobKey)

    return new Promise((resolve, reject) => {
        request
            .post()
            .then(_response => {
                dispatch({
                    type: DELETE_FRANCHISE_LOGO,
                    payload: { blobKey }
                })
                resolve()
            })
            .catch(err =>{
                console.error(err)
                reject()
            })
    })
}

export const fetchTopTextSuggestions = ({ franchiseName }) => async (dispatch) => {
    try {
      const topTexts = await makeRequest()
        .url(API.REPORT.GET_TOP_TEXTS_V2)
        .param('franchise', franchiseName)
        .get();
  
      dispatch({
        type: LOAD_TOP_TEXT_SUGGESTIONS,
        payload: topTexts,
      });
    } catch (error) {
      throw new Error(error);
    }
  };
  
export const updateTopTextSuggestions = ({topTexts}) => dispatch => {
    dispatch({
        type: LOAD_TOP_TEXT_SUGGESTIONS,
        payload: topTexts,
    })
}


export const loadChatGPTsuggestions = (chatGPTSuggestions) => dispatch => {
        dispatch({
            type: CHAT_GPT_SUGGESTIONS,
            payload: chatGPTSuggestions
        })
}

export const loadYoutubeAIsuggestions = (youtubeAIsuggestions) => dispatch => {
    dispatch({
        type: YOUTUBE_AI_SUGGESTIONS,
        payload: youtubeAIsuggestions
    })
}


export const loadAssetColors = (franchiseName, colors) => dispatch =>  {
    if(!franchiseName) {
        dispatch({
            type: LOAD_FRANCHISE_COLORS,
            payload: colors
        })
        return
    }
    window.eulerity.makeApiCall(`${API.FRANCHISE.GET_COLORS}?franchise=` + encodeURIComponent(franchiseName), 'GET', null, function(resp) {
        dispatch({
            type: LOAD_FRANCHISE_COLORS,
            payload: resp
        })
	});
}

export const deleteAssetColor = (hexCode, franchiseName) => dispatch =>  {
    window.eulerity.makeApiCall(`${API.FRANCHISE.REMOVE_COLOR}?franchise=` + encodeURIComponent(franchiseName) + '&hexCode=' + hexCode, 'POST', null, function() {
        window.eulerity.makeApiCall(`${API.FRANCHISE.GET_COLORS}?franchise=` + encodeURIComponent(franchiseName), 'GET', null, function(resp) {
            dispatch({
                type: LOAD_FRANCHISE_COLORS,
                payload: resp
            })
        });

    });
}

export const addAssetColor = (hexCode, franchiseName) => dispatch =>  {
    window.eulerity.makeApiCall(`${API.FRANCHISE.ADD_COLOR}?franchise=` + encodeURIComponent(franchiseName) + '&hexCode=' + hexCode, 'POST', null, function() {
        window.eulerity.makeApiCall(`${API.FRANCHISE.GET_COLORS}?franchise=` + encodeURIComponent(franchiseName), 'GET', null, function(resp) {
            dispatch({
                type: LOAD_FRANCHISE_COLORS,
                payload: resp
            })
	    });
    });
}



export const addFont = ({franchiseName, blobKey, callback}) => async dispatch =>  {
    let newFont = null
    try {
        newFont = await makeRequest()
            .url(API.FRANCHISE.ADD_FONT)
            .param('franchise', franchiseName)
            .param('blobKey', blobKey)
            .post()
    } catch (e) {
        console.error('Unable to add font: ', e)
        return
    }

    loadFontsToHtml([newFont])

    dispatch({
        type: LOAD_FONT,
        payload: newFont
    })

    callback?.()
}



export const deleteFont = (font, franchiseName) => async dispatch => {
    try {
        await makeRequest()
            .url(API.FRANCHISE.REMOVE_FONT)
            .param('franchise', franchiseName)
            .param('blobKey', font.blobKey)
            .post()
    } catch (e) {
        console.error('Unable to delete font: ', e)
        return
    }

    dispatch({
        type: DELETE_FONT,
        payload: font
    })
}


export const loadAllFonts = (franchiseName) => async (dispatch) => {

    let fonts = null
    try {
        let fontRequest = makeRequest()
            .url(API.FONT.GET_ALL)

        if(franchiseName) {
            fontRequest = fontRequest.param('franchise', franchiseName)
        }

        fonts = await fontRequest.get()
    } catch (e) {
        console.error('Unable to load fonts: ', e)
        return
    }

    loadFontsToHtml(fonts)

    dispatch({
        type: LOAD_ALL_FONTS,
        payload: fonts
    })
}

export const loadMemberFonts = (prepFonts) => dispatch =>  {
    dispatch({
        type: LOAD_ALL_FONTS,
        payload: prepFonts
    })
}

export const loadApprovals = franchiseName => dispatch => {
    if (!franchiseName) return;

    try {
        let list = []
        window.eulerity.makeBatchedApiCall({
            url: `/api/creative/listFranchiseUnreviewed?franchise=${encodeURIComponent(franchiseName)}`,
            dataCallback: function (resp) {
                resp.forEach(c => list.push(c))
            },
            doneCallback: function () {
                console.log('Approvals loaded:', list)
                dispatch({
                    type: LOAD_FRANCHISE_APPROVALS,
                    payload: list.sort((a,b) => (a.created < b.created) ? 1 : ((b.created < a.created) ? -1 : 0))
                })
            },
            retry: 3
        })
    } catch(err) {
        console.error('Unable to load approvals')
    }
}

export const updateGTMContainerId = (franchiseName) => dispatch => {
    window.eulerity.makeApiCall('/api/franchise/enableGtm?franchise=' + encodeURIComponent(franchiseName), 'POST', null, function(resp){
        dispatch({
            type: UPDATE_GTM_CONTAINER_ID,
            payload: resp.gtmContainerId
          })
    });
}

//LABELS
export const changeLabel = (newLabel, oldLabel, franchiseName, callback) => dispatch => {
    window.eulerity.makeApiCall(`/api/franchise/editLabel?oldLabel=${encodeURIComponent(oldLabel)}&newLabel=${encodeURIComponent(newLabel)}&franchise=${encodeURIComponent(franchiseName)}` ,'POST', null, function(resp) {
        dispatch({
            type: CHANGE_LABEL,
            newLabel,
            oldLabel,
        })
        dispatch({ type: LOAD_FRANCHISE_LABELS })
        callback?.(resp)
	});
}
export const deleteLabel = (oldLabel, franchiseName, callback) => dispatch => {
    window.eulerity.makeApiCall(`/api/franchise/editLabel?oldLabel=${encodeURIComponent(oldLabel)}&newLabel=&franchise=${encodeURIComponent(encodeURIComponent(franchiseName))}`,'POST', null, function(resp) {
        dispatch({
            type: DELETE_LABEL,
            oldLabel,
        })
        dispatch({ type: LOAD_FRANCHISE_LABELS })
        callback?.(resp);
	});
}

// TOKENS
export const refreshApiTokens = (franchiseName) => dispatch => {
    window.eulerity.makeApiCall('/api/token/list?franchise=' + encodeURIComponent(franchiseName), 'GET', null, function(resp) {
        dispatch({
            type: REFRESH_API_TOKENS,
            payload: resp
        })
    });
}

export const createUserToken = ({ defaultUid, description, scopes, callback }) => dispatch => {
    let url = '/api/token/create';
    if (defaultUid) {
        url += '?uid=' + defaultUid;
    }

	window.eulerity.makeApiCall(url, 'POST', {'description': description, 'scopes': scopes}, function(resp) {
        dispatch({
            type: TOKEN_APIKEY,
            payload: resp.id
        })
		callback?.()
	});
}

export const addDefaultLogo = ({uid, lid, blobKey, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/location/update?uid=${uid}&lid=${lid}`, 'POST', {id: lid, logo: blobKey}, function(resp) {
        dispatch({
            type: ADD_DEFAULT_LOGO,
            payload: blobKey
        })
        callback?.(resp)
    })
}

export const _updateKeywords = (locationIDs, keywords, updateType, cb, error_cb, onLocationKeywordsUpdated) => async dispatch => {
    let url

    if(updateType === KEYWORD_UPDATE_TYPES.ADD) {
        url = '/api/location/addKeywords'
    } else if (updateType === KEYWORD_UPDATE_TYPES.REPLACE) {
        url = '/api/location/update'
    } else if (updateType === KEYWORD_UPDATE_TYPES.REMOVE) {
        url = '/api/location/removeKeywords'
    } else {
        console.error('invalid keyword update type:', updateType)
        return
    }

    const keywordsByLocation = {}

    for(const {uid, lid} of locationIDs) {
        const key = lid + "_" + uid
        try {
            keywordsByLocation[key] = await new Promise((resolve, reject) => {
                window.eulerity.makeApiCall(`${url}?uid=${uid}&lid=${lid}`, 'POST', { keywords }, (resp) => {
                    resolve(resp.keywords)
                }, {
                    1000: reject
                })
            })
            onLocationKeywordsUpdated?.()
        } catch(err) {
            console.error("error updating keywords", err)
            if(!error_cb) {
                toast.error(`Oops, there was an error updating that campaign's keywords`)
            } else {
                error_cb({uid, lid})
            }
        }
    }

    dispatch({
        type: UPDATE_MULTIPLE_LOCATIONS_KEYWORDS,
        payload: {
            keywordsByLocation
        }
    })

    cb?.()
}

export const addKeywords = (locationIDs, keywords, cb, error_cb, onLocationKeywordsUpdated) => dispatch => {
    _updateKeywords(locationIDs, keywords, KEYWORD_UPDATE_TYPES.ADD, cb, error_cb, onLocationKeywordsUpdated)(dispatch)
}

export const deleteKeywords = (locationIDs, keywords, cb, error_cb, onLocationKeywordsUpdated) => dispatch => {
    _updateKeywords(locationIDs, keywords, KEYWORD_UPDATE_TYPES.REMOVE, cb, error_cb, onLocationKeywordsUpdated)(dispatch)
}

export const replaceKeywords = (locationIDs, keywords, cb, error_cb, onLocationKeywordsUpdated) => dispatch => {
    _updateKeywords(locationIDs, keywords, KEYWORD_UPDATE_TYPES.REPLACE, cb, error_cb, onLocationKeywordsUpdated)(dispatch)
}

export const deleteMultipleKeywords = (keywords, cb, onKeywordDeletedFromLocation) => async dispatch => {
    /*
        reduces to format:
        {
            [{ lid: xxx, uid: xxx }]: [kw1, kw2, ...]
        }
        where the key is a JSON-parseable string to get the locations lid and uid
        and the value is an array of keywords to delete from the location
    */
    const locationsToDeleteFrom = keywords.reduce((ob, kw) => {
        for(const loc of kw.locations) {
            const locIds = {
                lid: loc.id,
                uid: loc.user.raw.name
            }
            const key = JSON.stringify(locIds)

            if(!ob[key]) ob[key] = []

            ob[key].push(kw)
        }

        return ob
    }, {})


    let numFailed = 0

    // we loop through this object's entries and make only one api call per location
    for(const [key, keywordsToDelete] of Object.entries(locationsToDeleteFrom)) {
        const { lid, uid } = JSON.parse(key)

        const strippedKeywords = keywordsToDelete.map(kw => ({
            word: kw.word,
            matchType: kw.matchType,
            isNegative: kw.isNegative
        }))
        await new Promise((resolve) => {
            deleteKeywords([{lid, uid}], strippedKeywords, resolve, () => {
                numFailed++
            })(dispatch)
        })

        onKeywordDeletedFromLocation?.()
    }

    if(numFailed > 0) {
        toast.error(`Could not remove keywords for ${numFailed === 1 ? `a campaign` : numFailed + ` campaigns`}. Campaigns must have at least one keyword.`)
    }

    cb?.()
}