import Regex from "../constants/regex";

/**
 * A utility object to perform various operations related to strings.
 * @namespace StringUtil
 */
const StringUtil = {

  /**
   * A utility function that checks if a string contains any emojis.
   * @function hasEmoji
   * @param {string} str - The input string to be checked.
   * @returns {boolean} - True if the string contains emojis, otherwise false.
   */

  hasEmoji: (str: string = ""): boolean => Regex.EMOJI.test(str),

  /**
   * Checks if a string contains any special characters.
   * Example special characters include !@#$%^&*()_+\-=[]{};':"\\|,.<>\/?
   * Additional special characters can be added to the test as needed.
   * @function containsSpecialCharacters
   * @memberof StringUtil
   * @param {string} str - The input string to be checked.
   * @returns {boolean} - True if the string contains special characters, otherwise false.
   */

  containsSpecialCharacters: (str: string, specialChars: RegExp = /[!]/): boolean => specialChars.test(str),

  /**
   * A utility function that checks if a string contains consecutive special characters.
   * FormattedString will remove all whitespace between characters to check for consecutive special characters, such as '! !' (will return true).
   * @function repeatedSpecialCharacters
   * @param {string} string - The input string to be checked.
   * @returns {boolean} - True if the string contains consecutive special characters, otherwise false.
   */

  repeatedSpecialCharacters: (string: string = ""): boolean => {
    const format = Regex.REPEATED_SPECIAL_CHARS;
    const formattedString = string.split(' ').join('');
    return format.test(formattedString);
  },


  /**
  * Truncates a string to a specified length, appending ellipsis if necessary.
  * Important: There is a CSS property - text-overflow: ellipsis; - that has similar functionality.
  * Doc: https://developer.mozilla.org/en-US/docs/Web/CSS/text-overflow
  * @function truncateString
  * @memberof StringUtil
  * @param {string} str - The input string to be truncated.
  * @param {number} length - The maximum length of the truncated string.
  * @returns {string} - The truncated string with ellipsis appended if necessary.
  */

  truncateString: (str: string = "", length: number): string => {
    str = str.trim()
    if (str.length > length) return str.substring(0, length) + '...';
    return str;
  },

  /**
 * A utility function that checks if a given input is a valid non-empty string.
 * @function isValidString
 * @param {any} str - The input to be checked.
 * @returns {boolean} - True if the input is a non-empty string, otherwise false.
 */

  isValidString: (str: any): boolean => typeof str === 'string' && str.trim().length > 0,

  /**
   * Removes all emojis from a string.
   * @function removeEmojiFromString
   * @memberof StringUtil
   * @param {string} str - The input string.
   * @returns {string} - The string without emojis.
   */

  removeEmojiFromString: (str: string = ""): string => str.replace(Regex.EMOJI_GLOBAL, ''),

  /**
   * Capitalizes the first letter in a string, and converts the rest to lowercase.
   * @function capitalizeFirstLetter
   * @memberof StringUtil
   * @param {string} str - The input string.
   * @returns {string} - The string with the first letter capitalized.
   */
  capitalizeFirstLetter: (str: string = ""): string => {
    str = str.trim();
    if(!str) return ""
    return str[0].toUpperCase() + str.substring(1).toLowerCase()
  },

  /**
   * Converts a snake_case string to Title Case.
   * Input: "popup_click_Menu" output: Popup Click Menu
   * @function displaySnakeStr
   * @memberof StringUtil
   * @param {string} str - The snake_case string.
   * @param {string} [delimiter='_'] - The delimiter used in the snake_case string.
   * @returns {string} - The Title Case string.
   */

  displaySnakeStr: (str: string = "", delimiter: string = '_'): string => {
    return str
      .trim()
      .replace(new RegExp(delimiter + "+", "g"), ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  },

    /**
   * Converts a string to Title Case
   * Input: "this is a test" output: This Is A Test
   * True title case would ignore minor words but thats overkill right now
   * @function titleCase
   * @memberof StringUtil
   * @param {string} str - The string
   * @returns {string} - The Title Case string.
   */

    titleCase: (str: string): string => {
      return str.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
      }).join(' ')
    },

  /**
   * Extracts an alphanumeric string by removing specified characters.
   * @function getAlphaNumericString
   * @memberof StringUtil
   * @param {string} str - The input string.
   * @param {boolean} removeSpaces - Flag to determine whether to remove spaces.
   * @returns {string} - The alphanumeric string.
   */

  getAlphaNumericString: (str: string = "", removeSpaces: boolean): string => str.replace(removeSpaces ? /[^a-zA-Z0-9]/g : /[^a-zA-Z0-9 ]/g, ""),

  /**
 * A utility function that removes all whitespace characters from a given string.
 * @function stripWhitespace
 * @param {string} str - The input string.
 * @returns {string} - The string with all whitespace characters removed.
 */

  stripWhitespace: (str: string = ""): string => str.replace(/\s/g, ''),

  /**
   * Checks if string is a valid instagram handle
   * A valid instagram handle must:
   * Must start with a letter or number
   * Can only contain letters, numbers, underscores (_), and periods (.)
   * Length should be between 1 and 30 characters
   * @function isValidInstagramHandle
   * @param { string } instagramHandle - instagram handle string being tested
   * @returns { boolean } - True if valid, false otherwise
   */

  isValidInstagramHandle: (instagramHandle: string) : boolean => {
    return Regex.INSTAGRAM_HANDLE.test(instagramHandle)
  },
  /**
   * Gets number of bytes for a given string
   * @function getStringBytes
   * @param { string } str - The string to get byte length for
   * @returns { number } - Number of bytes
   */
  getStringBytes : (str: string): number => {
    const encoder = new TextEncoder()
    const encodedStr = encoder.encode(str)
    return encodedStr.byteLength
  },
  /**
   * Determines whether a string contains a character that is more than one byte
   * @function getStringBytes
   * @param { string } str - The string to check for multiple byte characters
   * @returns { boolean } - Whether the string has multiple byte characters
   */
  hasMultiByteCharacters : (str: string): boolean => {
    return str.length !== StringUtil.getStringBytes(str)
  }};


export default StringUtil