// * Constants
export const CATEGORY_TYPES = {
  TYPE: 'type',
  LABEL: 'label',
  USER: 'user',
  STATUS: 'status',
  OBJECTIVE: 'objective',
}

export const CATEGORY_OPTIONS = {
  LOCATION_TYPE_LIST: ["Primary Campaigns", "Secondary Campaigns"],
  STATUS_LIST: ['ACTIVE', 'INACTIVE', 'DRAFT'],
  PERMISSION_LIST: ['Advertising', 'Social Posting', 'Email', 'SMS']
}

export const CATEGORY_TAB_NAME = {
  LABEL: 'LABEL',
  TYPE: 'TYPE',
  STATUS: 'STATUS',
  USER: 'USER',
  OBJECTIVE: 'CAMPAIGN TYPE',
}