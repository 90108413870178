import React, { ReactNode } from 'react'
import { StyledAlert } from './styles'
import { IoMdWarning } from 'react-icons/io';
import { IoIosWarning } from "react-icons/io";
import { Small } from '../../styles/typography'
import { ALERT } from './util';
import { FiAlertCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';

type AlertProps = {
  type?: string;
  title?: string;
  message?: string | ReactNode;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

const Alert = ({ type, title, message, style={}, iconStyle={}, children } : AlertProps) => {
  const theme = useTheme()

  const renderAlertIcon = () => {
    switch (type) {
      case ALERT.WARNING.TYPE:
        return <IoMdWarning size='20'/>
      case ALERT.ALERT.TYPE:
        return <IoIosWarning size='20'/>
      case ALERT.INFO.TYPE:
        return <FiAlertCircle color={theme.color.accentColor2} size='20'/>
      default:
        return null
    }
  }

  return (
    <StyledAlert type={type} style={style}>
      <div style={{ alignSelf: 'start', lineHeight: 0, ...iconStyle}}>
        {renderAlertIcon()}
      </div>
      <div className='messageContainer'>
        {title && <Small bold>{title}</Small>}
        {typeof message === 'string' ? <Small>{message}</Small>: message}
      </div>
      {children}
    </StyledAlert>
  )
}

export default Alert