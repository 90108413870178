import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SiRedux } from "react-icons/si";
import { BreakPoint } from './style'
import { LogoutIcon, UserSettingsIcon } from './icons'
import SignOut from '../../helpers/signout'
import { SIDEBAR_EXPERIMENTS } from '../../helpers/experimentHelpers'
import { PATH } from '../../constants/urlPath'
import useDynamicSetting from '../../hooks/useDynamicSetting'
import { TextBubble, XS } from '../../styles/typography'
import { useSidebar } from '../../hooks/useSidebar'
import { Show } from '../reusableUI/components/Show'
import { ImWarning } from 'react-icons/im'
import { useTheme } from 'styled-components'
import { COLORS } from '../../constants/colors'
import { Theme } from '../../types/theme'
import { useNetworkLoginConnections } from '../../pages/userSettings/hooks/useNetworkLoginConnections'
import useEnvironment from '../../features/environment/useEnvironment'
import { EnvironmentIndicator } from '../../features/environment/style'
import ReduxStateModal from './ReduxStateModal'
import { useAccess } from '../../hooks/useAccess';
import { disabledAppStyles } from '../../styles';
import { IoColorPaletteOutline } from "react-icons/io5";
import BrandTheme from './BrandTheme';
import ModalV2 from '../modal/ModalV2/ModalV2';
import Button from '../button/Button';
import { Navbar } from './Navbar';
import useSidebarPages, { Folder, FolderName, Tab } from '../../hooks/useSidebarPages';

type SidebarProps = {
	isDisabled: boolean
}

const Sidebar = ({ isDisabled }: SidebarProps) => {
	const user = useSelector((s) => s.user)
	const { networkLoginConnections } = useNetworkLoginConnections();
	const { brandingBlob } = useDynamicSetting()
	const environment = useEnvironment()
	const [showExperimentToggles, setShowExperimentToggles] = useState(environment.isDevelopment)
	const [showLogout, setShowLogout] = useState(false)
	const [showReduxModal, setShowReduxModal] = useState(false)
	const [showBrandThemeModal, setShowBrandThemeModal] = useState(false)
	const { canManageFranchise } = useAccess()

    const [expandedFolder, setExpandedFolder] = useState<FolderName | null>('Content')

	const theme = useTheme() as Theme;

	const { collapsed } = useSidebar()
	const { PRODUCT_PAGES, MANAGEMENT_PAGES } = useSidebarPages()

	const hasInvalidNetworkLogins = useMemo(() => {
		if (networkLoginConnections === null) return false;

		return networkLoginConnections.some(connection => connection.invalid);
	}, [networkLoginConnections])

	const isDemoMode = false //Sometimes we need to demo things locally to Product

	return (
		<>
			<Navbar.Container
				id="sidebar"
				style={isDisabled ? disabledAppStyles : {}}
			>
				{
                    brandingBlob &&  (
                        <Navbar.LogoContainer>
                            <Navbar.Logo src={`/srv/${brandingBlob}`} />
                        </Navbar.LogoContainer>
                    )
                }
				<Show when={!environment.isProduction && !isDemoMode}>
					<EnvironmentIndicator
						onClick={() => {
							if (!environment.isDevelopment && !environment.isTest) return
							setShowExperimentToggles((prev) => !prev)
						}}
						environment={environment.name}
					>
						{collapsed ? environment.name.charAt(0) : environment.name}
					</EnvironmentIndicator>
				</Show>
				<Show when={showExperimentToggles && !isDemoMode}>
					<Navbar.Section style={{ padding: '0 1rem' }}>
						<Navbar.ExperimentFolder experiments={SIDEBAR_EXPERIMENTS} />
						<Navbar.DevToolTab onClick={() => setShowBrandThemeModal(true)}>
							<IoColorPaletteOutline />
							BrandTheme
						</Navbar.DevToolTab>
						<Navbar.DevToolTab onClick={() => setShowReduxModal(true)}>
							<SiRedux /> Redux State
						</Navbar.DevToolTab>
					</Navbar.Section>
				</Show>
                <Navbar.Section style={{ overflowY: 'scroll' }}>
                    {
                        PRODUCT_PAGES.concat(MANAGEMENT_PAGES).map(page => {
                            if (page.isFolder && 'tabs' in page) {
                                return (
                                    <Navbar.CollapsableFolder
                                        isExpanded={page.name === expandedFolder}
                                        setIsExpanded={() => setExpandedFolder(prev => prev === page.name ? null : page.name)}
                                        page={page as Folder} key={page.name}
                                    />
                                )
                            } else {
                                return <Navbar.TabLink key={page.name} tab={page as Tab} />
                            }
                        })
                    }
                </Navbar.Section>
				<BreakPoint style={{ marginTop: 'auto' }} />
				<Navbar.Section style={{ padding: '1rem 1rem 2rem', gap: '1rem' }}>
                    {canManageFranchise() && <TextBubble fontSize='12px' semibold style={{ alignSelf: 'start' }}>MANAGER</TextBubble>}
					<div>
						<XS ellipsis color="navbarTextColor">{user.name}</XS>
						<XS ellipsis color="navbarTextColor">{user.email}</XS>
					</div>
					{
						(user.providerId === "facebook.com" || user.providerId === "google.com") && (
							<Navbar.NetworkProviderIcon user={user} />
						)
					}
					<div>
						<Navbar.TabLink underline tab={{ name: 'Account Settings', path: PATH.USER_SETTINGS, icon: UserSettingsIcon, isFolder: false }} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
							{hasInvalidNetworkLogins && <ImWarning style={{ color: theme.color[COLORS.BRAND.WARNING] }} />}
						</Navbar.TabLink>
						<div
							style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer', padding: '0.5rem 0' }}
							onClick={() => setShowLogout(true)}
						>
							<LogoutIcon color={theme.color.greyAccent1} />
							<XS underline color='navbarTextColor'>Sign Out</XS>
						</div>
					</div>
				</Navbar.Section>
			</Navbar.Container>

            {/* MODALS */}

            {showReduxModal && (<ReduxStateModal onClose={() => setShowReduxModal(false)}/>)}
            {showBrandThemeModal && <BrandTheme onClose={() => setShowBrandThemeModal(false)}/>}
            {
                showLogout && (
                    <ModalV2
                        title="Are you sure you want to sign out?"
                        size="sm"
                        buttons={[
                            <Button variant='outline' onClick={() => setShowLogout(false)}>Cancel</Button>,
                            <Button onClick={() => SignOut()}>Sign Out</Button>
                        ]}
                    />
                )
            }
		</>
	)
}

export default Sidebar
