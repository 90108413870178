import { CREATIVE_KIND, POST_NETWORK } from "../../helpers/creative.util";
import { ReactComponent as BullHorn} from '../../assets/images/BullHorn.svg'
import { ReactComponent as LikedPost} from '../../assets/images/LikedPost.svg'
import { ReactComponent as Messages} from '../../assets/images/Messages.svg'
import { ReactComponent as OpenMail} from '../../assets/images/OpenMail.svg'
import { IconWrapper } from "../../pages/locations/style";
import { CONNECTIONS, LOCATION_CONNECTIONS } from "../connections/constants";
import { isDraftOrCanceledLocation } from "../../pages/locations/modals/settings/subscription/subscription.util";
import { sortByRecentDate } from "../../helpers/sorters";
import { gmtDateString } from "../../helpers/date.util";
import { Link, Small } from "../../styles/typography";
import Regex from "../../constants/regex";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { FiExternalLink } from "react-icons/fi";
import NetworkIcon from "../creatives/CreativeModalV2/NetworkIcon";
import { EulerityLocation } from "../../types/location";
import { UserInfo } from "../../types/user";
import { isGeoLocation, isGeoRadius } from "../../helpers/demographics/geoRadius";
import { Creative, Network } from "../creatives/creative.types";
import React from "react";
import { GenderTarget, genderTargetCopy } from "../../helpers/demographics/genderTargets";
import { IncomeTarget, incomeTargetCopy } from "../../helpers/demographics/incomeTarget";
import { AgeTarget, ageTargetCopy } from "../../helpers/demographics/ageTargets";
import { ParentTarget, parentTargetCopy } from "../../helpers/demographics/parentTargets";
import GoogleAnalytics from "../../assets/icons/GoogleAnalytics";
import { LocationConnections, WeightMapKey } from "../../types/primitives";
import { Focus, LOCATION_FOCUS } from "../../components/selector/layouts/LocationFocusLayout";
import { FACEBOOK_SPECIAL_AD_CATEGORIES } from "../../helpers/constants";
import { ENVIRONMENTS, getEnvironmentType } from "../environment/util";

export const LOCATION_STATUS = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    DECLINED: 'DECLINED',
    PENDING: 'PENDING',
    DRAFT: 'DRAFT',
    INACTIVE: 'INACTIVE'
} as const

//* Location Object Util Functions
/*
    Usage: These functions pass in a location object to return some value about the location
*/
export const getLocationUserEmail = (location: EulerityLocation, members: UserInfo[]) => members.find(m => m.id === location?.user.raw.name)?.email || ''
export const getLocationDisplayName = <T extends {
    name: string,
    nickname: string
}>(location: T) => `${location.name} (${location.nickname})`

/**
    Returns a status string for the location
    @param {object} Location - The Location object
    @param {boolean} capitalizeFirst - Whether the string should be displayed as 'Active' instead of 'ACTIVE" etc.
*/
export const getLocationStatus = (location: EulerityLocation) => {
    const {subscriptions} = location;
    if (isDraftOrCanceledLocation(location)) {
        return location?.subscriptions?.length ? LOCATION_STATUS.INACTIVE : LOCATION_STATUS.DRAFT
    } else if (subscriptions[0].status === LOCATION_STATUS.ACTIVE && subscriptions[0].chargedMonthlyUsd > 0) {
        return LOCATION_STATUS.ACTIVE
    } else if (subscriptions[0].pausedDate || subscriptions[0].declineDate || subscriptions[0].pendingDate) {
        // If any of these dates are defined, use the most recent one
        const {pausedDate, declineDate, pendingDate} = subscriptions[0]
        const datesToCompare = []
        if (pausedDate) datesToCompare.push({ date: new Date(gmtDateString(pausedDate)), statusLabel: LOCATION_STATUS.PAUSED })
        if (declineDate) datesToCompare.push({ date: new Date(gmtDateString(declineDate)), statusLabel: LOCATION_STATUS.DECLINED })
        if (pendingDate) datesToCompare.push({ date: new Date(gmtDateString(pendingDate)), statusLabel: LOCATION_STATUS.PENDING })

        datesToCompare.sort(sortByRecentDate)
        return datesToCompare?.[0]?.statusLabel
    } else {
        return LOCATION_STATUS.INACTIVE
    }
}

type ProductFeatures = {
    socialPostingFeatureEnabled: boolean,
    reputationManagementFeatureEnabled: boolean
    allowInactivePosting: boolean
}

export const getProductStatus = (location : EulerityLocation, product: Focus, features: ProductFeatures) => {
    // * Checking status for ADVERTISING page
    if (product === LOCATION_FOCUS.ADVERTISING) return getLocationStatus(location)

    // * Checking status for SOCIAL POSTING page
    if (product === LOCATION_FOCUS.POSTING) {
        // * Check if the user has socialPostingFeatureEnabled
        if (features.socialPostingFeatureEnabled) {
            return features.allowInactivePosting ? LOCATION_STATUS.ACTIVE : getLocationStatus(location)
        }
        // * If socialPostingFeatureEnabled is NOT ACTIVE we will default to inactive until they pay for it
        return LOCATION_STATUS.INACTIVE
    }

    // * Checking status for REPUTATION MANAGEMENT page
    if (product === LOCATION_FOCUS.REPUTATION_MANAGEMENT) {
        return features.reputationManagementFeatureEnabled ? LOCATION_STATUS.ACTIVE : LOCATION_STATUS.INACTIVE
    }

    return LOCATION_STATUS.INACTIVE
}

export const getPaidBy = (location: EulerityLocation) => {
    const {subscriptions} = location
    if (!subscriptions?.length) return '-'
    return subscriptions[0].managingUserKey ? 'Corporate' : 'Business Owner'
}

export const getParentTargets = (location: EulerityLocation) => {
    let targets = [];
    const { parentTargets } = location;
    if (!parentTargets?.length) return '-'
    if(parentTargets.includes("t")) targets.push('Parents');
    if(parentTargets.includes("f")) targets.push('Non-Parents');
    return targets.join(',')
}
export const getAgeTargets = (location: EulerityLocation) => {
    let targets = [];
    const { ageTargets } = location;
    if (!ageTargets?.length) return '-'
    if(ageTargets.includes("a")) targets.push('18-24');
    if(ageTargets.includes("b")) targets.push('25-34');
    if(ageTargets.includes("c")) targets.push('35-44');
    if(ageTargets.includes("d")) targets.push('45-54');
    if(ageTargets.includes("e")) targets.push('55-64');
    if(ageTargets.includes("f")) targets.push('65+');
    return targets.join(',')
}
export const getIncomeTargets = (location: EulerityLocation) => {
    let targets = [];
    const { incomeTargets } = location;
    if (!incomeTargets?.length) return '-'
    if(incomeTargets.includes("l")) targets.push('Modest');
    if(incomeTargets.includes("m")) targets.push('Comfortable');
    if(incomeTargets.includes("h")) targets.push('Affluent');
    return targets.join(',')
}
export const getGenderTargets = (location: EulerityLocation) => {
    let targets = [];
    const { genderTargets } = location;
    if (!genderTargets?.length) return '-'
    if(genderTargets.includes("m")) targets.push('Male');
    if(genderTargets.includes("f")) targets.push('Female');
    return targets.join(',')
}
export const getGeoradiusTargets = (location: EulerityLocation, decimalPts?: number) => {
    const formatLatLng = (num: number) => decimalPts ? num.toFixed(decimalPts) : num
    const geoTargets = location.geoTargets || [];
    let list = geoTargets.filter(isGeoRadius).map(t => `Latitude:${formatLatLng(t.geoRadius.latitude)} Longitude:${formatLatLng(t.geoRadius.longitude)} Radius:${t.geoRadius.radius}`);
    return list.join(',')
}
export const getGeolocationTargets = (location: EulerityLocation) => {
    const geoTargets = location.geoTargets || [];
    let list = geoTargets.filter(isGeoLocation).map(t => `Type:${t.targetType} Name:${t.canonicalName || t.name}`)
    return list.join(',')
}

export const getPrimaryLocationData = (primaryWebsafe: string, locations: EulerityLocation[]) => {
    if(!primaryWebsafe || !locations || !locations.length) return null
    let parentLocation = locations.find(loc => loc.websafe === primaryWebsafe)
    return parentLocation
}

export const loadCreativesForLocation = async (uid: string, _lid: string) => {
    const lid = parseInt(_lid)

    const loadedCreatives: Creative[] = []

    await new Promise((resolve) => {
        window.eulerity.makeBatchedApiCall({
            url: '/api/creative/list?uid=' + uid + "&lid=" + lid,
            dataCallback: function (resp: Creative[]) {
                resp.forEach((creative: Creative) => loadedCreatives.push(creative));
            },
            doneCallback: resolve,
            retry: 3,
        });
    })

    const creativesObj: {
        creatives: Creative[],
        videoCreatives: Creative[],
        posts: Creative[],
        videoPosts: Creative[]
    } = {
        creatives: [],
        videoCreatives: [],
        posts: [],
        videoPosts: []
    }

    for(const creative of loadedCreatives) {
        if(creative.kind === CREATIVE_KIND.CREATIVE) {
            creativesObj.creatives.push(creative)
        } else if (creative.kind === CREATIVE_KIND.VIDEO_CREATIVE) {
            creativesObj.videoCreatives.push(creative)
        } else if (creative.kind === CREATIVE_KIND.POST) {
            creativesObj.posts.push(creative)
        } else if (creative.kind === CREATIVE_KIND.VIDEO_POST) {
            creativesObj.videoPosts.push(creative)
        }
    }

    return creativesObj
}

export const getAdvertisingConnectionsCards = (connections: ReturnType<typeof getLocationConnections>) => {
    const fbAdvertisingActive = connections[LOCATION_CONNECTIONS.FACEBOOK_ADVERTISING]
    const igAdvertisingActive = connections[LOCATION_CONNECTIONS.INSTAGRAM_ADVERTISING]
    const googleAnalytics4Active = connections[LOCATION_CONNECTIONS.GOOGLE_ANALYTICS_4]
    const linkedInActive = connections[LOCATION_CONNECTIONS.LINKEDIN_ADVERTISING]

    let cards = [
        {
            text: "Facebook",
            active: fbAdvertisingActive,
            icon: <NetworkIcon network={POST_NETWORK.FACEBOOK} />,
        },
        {
            text: 'Instagram',
            active: igAdvertisingActive,
            icon: <NetworkIcon network={POST_NETWORK.INSTAGRAM} />,
        },
        {
            text: 'Google Analytics',
            active: googleAnalytics4Active,
            icon: <GoogleAnalytics />,
        },
        {
            text: 'LinkedIn',
            active: linkedInActive,
            icon: <NetworkIcon network={POST_NETWORK.LINKEDIN} />,
        }
    ]

    return cards.sort((a,b) => Number(b.active) - Number(a.active))
}

export const getPostingConnectionsCards = (connections: ReturnType<typeof getLocationConnections>) => {
    const fbPostingActive = connections[LOCATION_CONNECTIONS.FACEBOOK_POSTING]
    const igPostingActive = connections[LOCATION_CONNECTIONS.INSTAGRAM_POSTING]
    const googleBusinessActive = connections[LOCATION_CONNECTIONS.GOOGLE_BUSINESS]
    const linkedInPostingActive = connections[LOCATION_CONNECTIONS.LINKEDIN_POSTING]
    const twitterActive = connections[LOCATION_CONNECTIONS.TWITTER]

    let cards = [
        {
            text: "Facebook",
            active: fbPostingActive,
            icon: <NetworkIcon network={POST_NETWORK.FACEBOOK} />,
        },
        {
            text: 'Instagram',
            active: igPostingActive,
            icon: <NetworkIcon network={POST_NETWORK.INSTAGRAM} />,
        },
        {
            text: 'Google Business Profile',
            active: googleBusinessActive,
            icon: <NetworkIcon network={POST_NETWORK.GMB} />
        },
        {
            text: 'LinkedIn',
            active: linkedInPostingActive,
            icon: <NetworkIcon network={POST_NETWORK.LINKEDIN} />,
        },
        {
            text: 'X',
            active: twitterActive,
            icon: <NetworkIcon network={POST_NETWORK.TWITTER} />
        }
    ]

    return cards.sort((a,b) => Number(b.active) - Number(a.active))
}

// * We'll be utilizing INSTAGRAM_ADVERTISING due to it's more restrictive nature of having to accept the request within the linkedin ui (posting is just a general connection)
export const getReputationManagementConnectionCards = (connections: ReturnType<typeof getLocationConnections>) => {
    const fbPostingActive = connections[LOCATION_CONNECTIONS.FACEBOOK_POSTING]
    const igPostingActive = connections[LOCATION_CONNECTIONS.INSTAGRAM_POSTING]
    const googleBusinessActive = connections[LOCATION_CONNECTIONS.GOOGLE_BUSINESS]
    const linkedInActive = connections[LOCATION_CONNECTIONS.LINKEDIN_POSTING]

    const cards = [
        {
            text: "Facebook",
            active: fbPostingActive,
            icon: <NetworkIcon network={POST_NETWORK.FACEBOOK} />,
        },
        {
            text: 'Instagram',
            active: igPostingActive,
            icon: <NetworkIcon network={POST_NETWORK.INSTAGRAM} />,
        },
        {
            text: 'LinkedIn',
            active: linkedInActive,
            icon: <NetworkIcon network={POST_NETWORK.LINKEDIN} />,
        },
        {
            text: 'Google Business Profile',
            active: googleBusinessActive,
            icon: <NetworkIcon network={POST_NETWORK.GMB} />,
        }
    ]

    return cards.sort((a,b) => Number(b.active) - Number(a.active))
}

export const getNetworkPageLinkComponent = (location: EulerityLocation, network: Network) => {
    const { url, pageName } = getNetworkPageUrl(location, network)

    if (!url) return <Small semibold>None Found</Small>
    return <Link target="_blank" rel="noreferrer" href={url}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}><strong>{pageName}</strong><FiExternalLink /></div>
    </Link>
}

export const getInstagramPageURL = (username: string) => `https://instagram.com/${username}`

export const getFacebookPageURL = (facebookPageId: string) => `https://facebook.com/${facebookPageId}`

export const getNetworkPageUrl = (location: EulerityLocation, network: Network) => {
    let url = ''
    let pageName = ''

    const { facebookPageName, facebookPageId, businessInstagramUsername } = location

    switch (network) {
        case POST_NETWORK.INSTAGRAM:
            if (!businessInstagramUsername) break;
            url = getInstagramPageURL(businessInstagramUsername)
            pageName = businessInstagramUsername
            break;
        case POST_NETWORK.FACEBOOK:
            if (!facebookPageId) break;
            url = getFacebookPageURL(facebookPageId)
            pageName = facebookPageName
            break;
        default:
            break;
    }

    return { url, pageName }
}

// * Function will take a given location and check if it is a search only campaign
export const isSearchOnlyCampaign = (location: EulerityLocation) => {
    // If a location/blueprint doesn't have a weightMap, then we know it isn't search only (null means it is using system default settings)
    if (!location?.weight) return false
    const { AdwordsSearchCampaign, ...campaignTypeKeys} = location?.weight?.map;
    const isSearchOnlyCampaign =  Object.values(campaignTypeKeys).every((weight) => weight === 0);

    if (isSearchOnlyCampaign && (AdwordsSearchCampaign ?? 0) > 0) return true
    else return false
}

export const isRunningOnChannel = (location: EulerityLocation, channelKey: WeightMapKey) => {
    return  (location.weight?.map[channelKey] || 0) > 0
}

export const isRunningOnFacebook = (location: EulerityLocation) => {
    const facebookChannelKeys = Object.keys(location.weight?.map || {})
                                        .filter(key => key.toLowerCase().includes('facebook')) as WeightMapKey[]
    return facebookChannelKeys.some(key => (location.weight?.map[key] || 0) > 0)
}

export const CONNECTION_STATUS = {
    NOT_CONNECTED: 0,
    PENDING: 1,
    CONNECTED: 2,
    NEEDS_CONFIG: 3
}

export const LOCATION_CONNECTION_STATUS = {
    NOT_CONNECTED: "NOT_CONNECTED",
    PENDING: "PENDING",
    CONNECTED: "CONNECTED",
    NEEDS_CONFIG: "NEEDS_CONFIG"
}

export const CONNECTION_STATUS_COPY = {
    [CONNECTION_STATUS.NOT_CONNECTED]: "Disconnected",
    [CONNECTION_STATUS.CONNECTED]: "Connected",
    [CONNECTION_STATUS.PENDING]: "Pending Connection",
    [CONNECTION_STATUS.NEEDS_CONFIG]: "Needs Configuration"
}

// * Returns all connections STATUSES found on a given location based off the connections field (found on locations object)
export const getLocationConnections = (location: EulerityLocation | undefined) => {
    // * Init connections
    const connections = {
        [LOCATION_CONNECTIONS.FACEBOOK_ADVERTISING]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.FACEBOOK_POSTING]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.INSTAGRAM_ADVERTISING]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.INSTAGRAM_POSTING]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.LINKEDIN_ADVERTISING]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.LINKEDIN_POSTING]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.GOOGLE_ANALYTICS_4]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.GOOGLE_BUSINESS]: CONNECTION_STATUS.NOT_CONNECTED,
        [LOCATION_CONNECTIONS.TWITTER]: CONNECTION_STATUS.NOT_CONNECTED,
    }

    if (!location?.connections) return connections

    const { connections: locationConnections } = location

    // * Map Connections * //
    for (const network in connections) {
        // * For every key (that is found in the locations connections)
        if (locationConnections[network as keyof LocationConnections]) {
            // * Check the networks status
            const networkStatus = locationConnections[network as keyof LocationConnections].status

            // * Map based off the status value
            if (networkStatus === LOCATION_CONNECTION_STATUS.CONNECTED) {
                connections[network as keyof LocationConnections] = CONNECTION_STATUS.CONNECTED
            } else if (networkStatus === LOCATION_CONNECTION_STATUS.PENDING) {
                connections[network as keyof LocationConnections] = CONNECTION_STATUS.PENDING
            } else if (networkStatus === LOCATION_CONNECTION_STATUS.NEEDS_CONFIG) {
                connections[network as keyof LocationConnections] = CONNECTION_STATUS.NEEDS_CONFIG
            } else {
                connections[network as keyof LocationConnections] = CONNECTION_STATUS.NOT_CONNECTED
            }
        }
    }

    return connections
}

// ! This is an older function being being re-introduced to avoid utilziing the new "CONNECTIONS" field on the location object - as mobile is not yet using it. (This function spits out which connections are found on the given campaign)
export const getLocationConnectionsRmi = (location: EulerityLocation | undefined) => {
    const connections = {
        [CONNECTIONS.FACEBOOK_POSTING]: CONNECTION_STATUS.NOT_CONNECTED,
        [CONNECTIONS.INSTAGRAM_POSTING]: CONNECTION_STATUS.NOT_CONNECTED,
        [CONNECTIONS.LINKEDIN]: CONNECTION_STATUS.NOT_CONNECTED,
        [CONNECTIONS.GOOGLEBUSINESS]: CONNECTION_STATUS.NOT_CONNECTED,
    }

    if (!location) return connections

    const hasFBPosting = location.facebookPageId && (location.facebookAccessGranted || location.hasFacebookOAuthToken)
    const hasGmb = location.hasGmbRefreshToken && location.gmbAccountName && location.gmbLocationName

    // * Check Facebook Posting
    if (hasFBPosting) connections[CONNECTIONS.FACEBOOK_POSTING] = CONNECTION_STATUS.CONNECTED

    // * Check Instagram Posting
    if (location.businessInstagramUsername) connections[CONNECTIONS.INSTAGRAM_POSTING] = CONNECTION_STATUS.CONNECTED

    // * In the case of staging we ALWAYS want Instagram Connected
    if (getEnvironmentType() === ENVIRONMENTS.STAGING) connections[CONNECTIONS.INSTAGRAM_POSTING] = CONNECTION_STATUS.CONNECTED

    // * LinkedIn
    if (location.linkedInOrgId) connections[CONNECTIONS.LINKEDIN] = location.linkedInAccessGranted ? CONNECTION_STATUS.CONNECTED : CONNECTION_STATUS.PENDING

    // * Google Business
    if (hasGmb) connections[CONNECTIONS.GOOGLEBUSINESS] = CONNECTION_STATUS.CONNECTED

    return connections
}

// ! This is an older function being being re-introduced to avoid utilziing the new "CONNECTIONS" field on the location object - as mobile is not yet using it. (These spit out the cards on the summary section)
export const getConnectionsCardsRmi = (connections: ReturnType<typeof getLocationConnectionsRmi>) => {
    const fbPostingActive = connections[CONNECTIONS.FACEBOOK_POSTING]
    const igPostingActive = connections[CONNECTIONS.INSTAGRAM_POSTING]
    const linkedInActive = connections[CONNECTIONS.LINKEDIN] === 2 // * We want to be inline with mobile here, pending should be considered as not connected (1 => pending, 2 => connected)
    const googleBusinessActive = connections[CONNECTIONS.GOOGLEBUSINESS]

    let cards = [
        {
            text: 'Facebook',
            active: fbPostingActive,
            icon: <NetworkIcon network={POST_NETWORK.FACEBOOK} />,
        },
        {
            text: 'Instagram',
            active: igPostingActive,
            icon: <NetworkIcon network={POST_NETWORK.INSTAGRAM} />,
        },
        {
            text: 'LinkedIn',
            active: linkedInActive,
            icon: <NetworkIcon network={POST_NETWORK.LINKEDIN} />,
        },
        {
            text: 'Google Business Profile',
            active: googleBusinessActive,
            icon: <NetworkIcon network={POST_NETWORK.GMB} />,
        }
    ]

    return cards.sort((a,b) => Number(b.active) - Number(a.active))
}

export const formatPhoneNumberStr = (str: string) => {
    if (!str) return ""

    const number = new AsYouType('US').input(str);

    return number
}

// Validate Fields Generally
export const validateStringLength = (str: string) => !str?.trim().length ? 'This field is required' : ''

// Validate Fields According to Specific Rules
export const validateLocationName = (name: string) => {
    if (!name?.trim().length) return 'This field is required'
    if (name?.length > 30) return 'Please enter a shorter name under 30 characters'
    return ''
}

export const validatePhoneNumber = (number: string) => {
    if (!number?.trim().length) return 'This field is required'
    if (!Regex.PHONE.test(number)) return 'Invalid phone number'
    return ''
}


export const validateLatLng = (latitude: number | undefined, longitude: number | undefined) => {
    //* if not lat or long return invalid address
    if (!latitude || !longitude) return 'Invalid Address'
    return ''
}

// Basic URL checking, will essentially just check for .{extension}
export const validateLandingPage = (url: string) => { //This only verifies user input. Does not run it through backend
    if (!url?.trim().length) return 'This field is required'
    const regex = new RegExp(Regex.URL)
    return url.match(regex) ? "" : "Please enter a valid URL"
}

export const prependProtocol = (url: string) => {
  if (url.includes("https://") || url.includes("http://")) return url
  return "http://" + url
}

export const getCountryCodeForPhoneNumber = (phone: string) => {
    return parsePhoneNumber(phone).country
};

export const FIELD = {
    UID: 'uid',
    PLACES_ID: 'placesId',
    PRIMARY_LOCATION: 'primaryLocation',
    NAME: 'name',
    NICKNAME: 'nickname',
    WEBSITE: 'website',
    PHONE: 'phone',
    PHONE_E164: 'phoneE164',
    STREET_ADDRESS: 'streetAddress',
    COUNTRY_CODE: 'countryCode',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    PROFILE_TEMPLATE_ID: 'profileTemplateId',
    LOGO: 'logo',
    KEYWORDS: 'keywords',
    VARIABLE_VALUES: 'variableValues',
    GEOTARGETS: 'geoTargets',
    FOCUSES: 'focuses',
    OBJECTIVE: 'objective',
} as const

export const DETAILS_FORM_FIELDS = [
    FIELD.NAME,
    FIELD.STREET_ADDRESS,
    FIELD.WEBSITE,
    FIELD.PHONE,
    FIELD.COUNTRY_CODE
] as const

export const generateNickname = ({ name, isSecondary, existingSecondariesNum } : {
    name: string,
    isSecondary: boolean,
    existingSecondariesNum: number
}) => {
    // Primary Campaign Nickname Format: {name} Primary Campaign
    let nickname = name
    if (!isSecondary) {
        if (!nickname.toLowerCase().includes('primary campaign')) {
            nickname += ` Primary Campaign`
        }
    } else {
        // Secondary Campaign Nickname Format: {name} Secondary Campaign {N}
        if (!nickname.toLowerCase().includes('secondary campaign')) {
            nickname += ` Secondary Campaign`
        }
        nickname += ` ${existingSecondariesNum + 1}`
    }

    return nickname
}

const lengthOfDemographic = (demographic: Record<string, any>) => Object.keys(demographic).length;

export const hasDemographics = (blueprint: {
    genderTargets?: GenderTarget[];
    incomeTargets?: IncomeTarget[];
    ageTargets?: AgeTarget[];
    parentTargets?: ParentTarget[];
}) => {
    const { genderTargets, incomeTargets, ageTargets, parentTargets } = blueprint;
    return (
        ((genderTargets?.length ?? 0) > 0 && (genderTargets?.length ?? 0) < lengthOfDemographic(genderTargetCopy)) ||
        ((incomeTargets?.length ?? 0) && (incomeTargets?.length ?? 0) < lengthOfDemographic(incomeTargetCopy)) ||
        ((ageTargets?.length ?? 0) > 0 && (ageTargets?.length ?? 0) < lengthOfDemographic(ageTargetCopy)) ||
        ((parentTargets?.length ?? 0) > 0 && (parentTargets?.length ?? 0) < lengthOfDemographic(parentTargetCopy))
    );
};

export const showGoogleSpecialAdCategory = (blueprint: {
    genderTargets?: GenderTarget[];
    ageTargets?: AgeTarget[];
    parentTargets?: ParentTarget[];
}) => {
    const { genderTargets, ageTargets, parentTargets } = blueprint;
    return (
        ((genderTargets?.length ?? 0) === 0 || (genderTargets?.length ?? 0) === lengthOfDemographic(genderTargetCopy)) &&
        ((ageTargets?.length ?? 0) === 0 || (ageTargets?.length ?? 0) === lengthOfDemographic(ageTargetCopy)) &&
        ((parentTargets?.length ?? 0) === 0 || (parentTargets?.length ?? 0) === lengthOfDemographic(parentTargetCopy))
    );
}
export const hasFacebookSpecialAdCategory = (location: EulerityLocation) =>
    (location.facebookSpecialAdCategory || FACEBOOK_SPECIAL_AD_CATEGORIES.NONE) !== FACEBOOK_SPECIAL_AD_CATEGORIES.NONE;

export const hasGoogleSpecialAdCategory = (location: EulerityLocation) =>
    (location.googleSpecialAdCategory || FACEBOOK_SPECIAL_AD_CATEGORIES.NONE) !== FACEBOOK_SPECIAL_AD_CATEGORIES.NONE;